import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import SimpleTable from "@/components/SimpleTable";
import { formatRelativeDateTime, showErrorToast, showSuccessToast } from "@/fns";
import { BREAKPOINTS, useMediaBreakpoint } from "@/hooks/useBreakpoints";
import { openGpsDeviceEditModal } from "@/modals/GpsDeviceEditModal";
import { ActionIcon, Button, Group, LoadingOverlay } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { noop } from "@tanstack/react-table";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

const columnDef = [
  {
    id: "companyName",
    accessorFn: (row) => row.company.name,
    header: "Company",
    metadata: { filter: true },
  },
  { accessorKey: "vehicleName", header: "Name" },
  { accessorKey: "driverName", header: "Driver" },
  { accessorKey: "year", header: "Year" },
  {
    id: "vehicleMakeModel",
    accessorFn: (row) => `${row.make} ${row.model}`,
    header: "Vehicle Make & Model",
  },
  { accessorKey: "modelNumber", header: "Model #" },
  { accessorKey: "devicePhoneNumber", header: "Device Phone #" },
  { accessorKey: "uid", header: "UID" },
  {
    id: "lastPing",
    accessorFn: (row) => formatRelativeDateTime(row.lastPoint?.triggerTime),
    header: "Last Comm",
  },
];

const columnsToShow = {
  [BREAKPOINTS.xl]: 9,
  [BREAKPOINTS.lg]: 7,
  [BREAKPOINTS.md]: 5,
  [BREAKPOINTS.sm]: 3,
  [BREAKPOINTS.xs]: 3,
};

const GpsDeviceList = () => {
  const queryClient = useQueryClient();
  const breakpoint = useMediaBreakpoint();

  const { data, error, isLoading, isError } = useQuery(["adminVehicles"], api.adminGetVehicles);

  const handleAddDevice = () => {
    openGpsDeviceEditModal({ vehicleId: null, onSave: (data) => handleCreateDevice(data) });
  };

  const handleCreateDevice = async (data) => {
    try {
      await api.adminCreateVehicle(data);
      showSuccessToast("Device Added");
    } catch (err) {
      showErrorToast("Add Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["adminVehicles"]);
    }
  };

  const handleEditDevice = (vehicleId) => {
    openGpsDeviceEditModal({ vehicleId, onSave: (data) => handleUpdateDevice(vehicleId, data) });
  };

  const handleUpdateDevice = async (vehicleId, data) => {
    try {
      await api.adminUpdateVehicle(vehicleId, data);
      showSuccessToast("Device Saved");
    } catch (err) {
      showErrorToast("Save Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["adminVehicles"]);
    }
  };

  const handleDeleteDevice = (id) => {
    openConfirmModal({
      title: "Are you sure you want to delete this device?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: noop,
      onConfirm: () => doDeleteDevice(id),
    });
  };

  const doDeleteDevice = async (id) => {
    try {
      await api.adminDeleteVehicle(id);
      showSuccessToast("Device Deleted");
    } catch (err) {
      showErrorToast("Error deleting device", err.message);
    } finally {
      queryClient.invalidateQueries(["adminVehicles"]);
    }
  };

  const columns = [
    ...columnDef.slice(0, columnsToShow[breakpoint]),
    {
      id: "actions",
      cell: ({ row }) => (
        <Group position="right" noWrap>
          <ActionIcon color="green" variant="outline" onClick={() => handleEditDevice(row.original.vehicleId)}>
            <FaPencilAlt />
          </ActionIcon>
          <ActionIcon color="red" variant="outline" onClick={() => handleDeleteDevice(row.original.vehicleId)}>
            <FaTrashAlt />
          </ActionIcon>
        </Group>
      ),
      metadata: { width: 1 },
    },
  ];

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <SimpleTable
      title="GPS Devices"
      data={data}
      columns={columns}
      fontSize="sm"
      searchable
      rightSection={<Button onClick={handleAddDevice}>Add a GPS Device</Button>}
      initialSort={[{ id: "companyName", desc: false }]}
    />
  );
};

export default GpsDeviceList;
